<template src="./EditEventModal.html">
</template>

<script>
import eventsService from "@/services/Events";
import categoryService from "@/services/Category";
import debounce from 'lodash/debounce';

export default {
  name: 'edit-event-modal',
  props: {
    name: {type: String},
    category: {type: String},
    idCategory: {type: String},
    moderator: {type: Array},
    date: {type: Date},
    initialHour: {type: Date},
    finalHour: {type: Date},
    videoURL: {type: String},
    description: {type: String},
    aforo: {type: String},
    idEvent: {type: Number},
    openEventModifyModal: {type: Boolean, defaul: false},
    code: {type: String}
  },
  data() {
    return {
      categoryList: null,
      moderatorSearchExpression: '',
      moderatorSearch: [],
    }
  },
  async beforeCreate() {
    try {
      this.categoryList = await categoryService.getCategoriesWhitSubcategory()
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeEventModify')
    },
    formatter(d) {
      return d.toLocaleDateString()
    },
    editEvent() {
      const categoryImage = this.categoryList.filter(cat => cat.id === this.idCategory)
      const obj = {
        name: this.name,
        description: this.description,
        idCategory: parseInt(this.idCategory),
        moderator: this.moderator.arroba_id,
        date: this.date,
        initialHour: this.initialHour,
        finalHour: this.finalHour,
        aforo: parseInt(this.aforo, 10),
        videoURL: this.videoURL,
        idEvent: this.idEvent,
        code: this.code,
        imagen: categoryImage[0].imagen ? categoryImage[0].imagen : 'https://storage.googleapis.com/arrobamedellin.appspot.com/Img_default.png'
      }
      eventsService.editEvent(obj)
        .then(() => {
          this.$buefy.toast.open({
            duration: 5000,
            position: "is-bottom",
            message: "Se editó el evento con éxito",
            type: "is-success"
          });
          this.closeModal()
        })
        .catch(error => {
          this.$buefy.toast.open({
            duration: 5000,
            position: "is-bottom",
            message: "Falló la edición del evento. Verifique los campos.",
            type: "is-error"
          });
          this.closeModal()
          console.error(error)
        })
        .finally(() => this.$emit('eventEdited'))
    },
    searchExpression: debounce(function (name) {
      if (!name.length) {
        this.moderatorSearch = null
        return
      }
      setTimeout(() => {
        eventsService.getModerators(this.moderatorSearchExpression)
          .then(response => {
            this.moderatorSearch = [];
            response.forEach((item) => this.moderatorSearch.push(item))
          })
          .catch(error => {
            this.moderatorSearch = []
            console.error(error)
          })
      })
    }, 300)
  },
  computed: {
    disableButton() {
      const {name, category, moderator, date, initialHour, finalHour, videoURL, description, aforo} = this;
      return name && category && moderator && date && initialHour && finalHour && videoURL && description && aforo;
    }
  }
}
</script>

<style lang="scss" scoped src="./EditEventModal.scss">

</style>
